// ** Initial user ability
export const initialAbility = [
  {
    action: 'manage',
    subject: 'third'
  },
  {
    action: 'manage',
    subject: 'demo'
  },
  
]

export const _ = undefined
