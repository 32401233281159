import { useEffect, useState } from "react"
import { AuthContext, UserContext } from "./context"

function AppContext({ children }) {
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)

  const setUserInformation = () => {
    const retrieveToken = window.localStorage.getItem(
      process.env.REACT_APP_TRIP_MERCHANT_TOKEN_KEY
    )
    const userData = window.localStorage.getItem(
      process.env.REACT_APP_TRIP_MERCHANT_USER_KEY
    )

    if (retrieveToken === "undefined") {
      window.localStorage.removeItem(
        process.env.REACT_APP_TRIP_MERCHANT_TOKEN_KEY
      )
      window.localStorage.removeItem(
        process.env.REACT_APP_TRIP_MERCHANT_USER_KEY
      )

      return
    }

    if (retrieveToken && retrieveToken !== "undefined") {
      setToken(retrieveToken)
      setUser(JSON.parse(userData))
    }
  }

  useEffect(() => {
    setUserInformation()
  }, [])

  return (
    <>
      <UserContext.Provider value={{ user, setUser }}>
        <AuthContext.Provider value={{ token, setToken }}>
          {children}
        </AuthContext.Provider>
      </UserContext.Provider>
    </>
  )
}

export default AppContext
